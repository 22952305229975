import { Box, Flex } from "@theme-ui/components"
import { useResponsiveValue } from "@theme-ui/match-media"
import React, { useContext, useEffect, useRef, useState } from "react"
import Logo from "../assets/logo.svg"
import { InterfaceContext } from "../context/interface"
import "./base.css"
import Container from "./container"
import CookiePopup from "./cookie-popup"
import DesktopNavigation from "./desktop-navigation"
import MobileNavigation from "./mobile-navigation"
import Popup from "./popup/popup"
import CaretDown from "./svg/caret-down"

export default ({
  nav,
  subNav,
  prefix,
  shouldShowNav,
  shouldShowMobileNav,
  isFeatured,
  featuredCases,
  children,
  locale,
  location,
  renderAboveFold,
  ...rest
}) => {
  const [lastScroll, setLastScroll] = useState(0)
  const [scrolled, setScrolled] = useState(false)
  const mainRef = useRef()
  const { setLocale } = useContext(InterfaceContext)

  const isSmall = useResponsiveValue([true, false])
  useEffect(() => {
    if (isSmall) {
      if (localStorage) {
        localStorage.removeItem("bf")
      }
      return
    }

    if (mainRef && renderAboveFold) {
      if (localStorage) {
        const scroll = localStorage.getItem("bf")
        if (scroll) {
          mainRef.current.scrollIntoView()
          return
        }
      }

      const handler = (e) => {
        if (scrolled) {
          return
        }

        if (lastScroll < 100) {
          if (lastScroll < window.scrollY) {
            if (mainRef.current) {
              mainRef.current.scrollIntoView({ behavior: "smooth" })
              setScrolled(true)
              if (localStorage) {
                localStorage.setItem("bf", "true")
              }
            }
          }
        }

        setLastScroll(window.scrollY)
      }
      window.addEventListener("scroll", handler)

      return () => {
        window.removeEventListener("scroll", handler)
      }
    }
  }, [mainRef, renderAboveFold, isSmall])

  useEffect(() => {
    setLocale(locale)
  }, [])
  return (
    <Container {...rest}>
      <CookiePopup prefix={prefix} />
      <Popup />
      {(shouldShowNav || shouldShowMobileNav) && (
        <MobileNavigation sx={{
          display: ["flex", "none"]
        }} nav={nav} subNav={subNav} location={location} />
      )}
      {renderAboveFold && (
        <Box
          px={[4, 0]}
          pr={4}
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#1A1F25",
            height: "100vh",
          }}
        >
          <Flex
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              width: ["0", "600px"],
              zIndex: [1001, "unset"],
            }}
          />
          <Flex
            sx={{
              width: "100%",
              flexDirection: ["column", "row"],
              alignItems: ["start", "end"],
            }}
          >
            <Box sx={{ width: ["80px", "95px"] }} pb={5} pt={[0, 6]} mr={5}>
              <img src={Logo} />
            </Box>
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                flex: 1,
              }}
            >
              {renderAboveFold()}
              <Flex
                mt={4}
                sx={{
                  alignItems: "center",
                  fontSize: ["10px", 2],
                  gap: 2,
                  fontWeight: 300,
                  fontFamily: "pragmatica-extended",
                  color: "#717A89",
                }}
                pb={5}
              >
                SCROLL DOWN
                <CaretDown />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
      <Flex ref={mainRef}>
        {shouldShowNav && (
          <DesktopNavigation
            nav={nav}
            subNav={subNav}
            featuredCases={featuredCases}
            isFeatured={isFeatured}
            location={location}
            sx={{ display: ["none !important", "flex !important"] }}
          />
        )}
        <Flex
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            flexDirection: "column",
            backgroundColor: "background",
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Container>
  )
}
