import React from "react"

const CaretDown = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.15625 4L7.28958 10" stroke="currentColor" />
    <path d="M12.8438 4L6.71042 10" stroke="currentColor" />
  </svg>
)

export default CaretDown
