import React from "react"
import { Box } from "theme-ui"

const RichText = (props) => {
  const { html, sx, enlarge, center } = props

  const baseFontSize = props.baseFontSize ? props.baseFontSize : "inherit"

  const handleEmailLinkClicks = (event) => {
    // If clicking on a mailto link, open it in a pop-up window
    if (
      event.target.tagName === "A" &&
      event.target.href.startsWith("mailto:")
    ) {
      event.preventDefault()
      window.open(event.target.href, "_blank")
    }
  }

  const handleMapLinkClicks = (event) => {
    // If clicking on a map link, open it in a pop-up window
    if (event.target.tagName === "A" && event.target.href.includes("maps")) {
      event.preventDefault()
      window.open(event.target.href, "_blank")
    }
  }

  const handleLinkClicks = (event) => {
    handleEmailLinkClicks(event)
    handleMapLinkClicks(event)
  }

  return (
    <Box
      onClick={handleLinkClicks}
      sx={{
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        strong: { fontWeight: "400" },
        fontSize: enlarge ? "15px" : baseFontSize,
        lineHeight: enlarge ? "1.5em" : "inherit",
        textAlign: center ? "center" : "left",
        strong: {
          fontWeight: "700",
        },
        em: {
          fontStyle: "italic",
          fontWeight: "300",
          fontSize: "9px",
          lineHeight: "12px",        
        },
        p: {
          marginTop: 0,
          mb: 0,
        },
        h1: {
          lineHeight: "30px",
          fontFamily: "pragmatica-extended",
          fontSize: ["20px"],
          mt: 0,
          fontWeight: "300",
          "& > br": {
            display: ["none", "block"],
          },
        },
        h2: {
          lineHeight: "1.5rem",
          fontFamily: "pragmatica-extended",
          fontSize: ["20px","22px"],
          "& > br": {
            display: ["none", "block"],
          },
          mt: 0,
          fontWeight: "300",
        },
        h3: {
          lineHeight: ["16px","18px"],
          fontFamily: "pragmatica-extended",
          fontSize: ["12px", "13px"],
          mt: 0,
          fontWeight: "300",
          "a[href*=maps]": {
            textDecoration: "none",
          }
        },
        ...sx,
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default RichText
