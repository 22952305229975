import { Box, Flex } from "@theme-ui/components"
import { Link } from "gatsby"
import React, { useContext } from "react"

import { useLocation } from "@reach/router"
import Logo from "../assets/logo.svg"
import { InterfaceContext } from "../context/interface"
import FeaturedCases from "./featured-case-nav"
import NavLink from "./nav-link"
export default ({
  nav,
  subNav,
  isFeatured: isFeaturedPage,
  featuredCases,
  location,
  sx,
}) => {
  const { locale, isGridView, toggleGridView } = useContext(InterfaceContext)
  const [lang] = locale.split("-")
  let prefix = "/"
  if (lang !== "en") {
    prefix = `/${lang}/`
  }

  return (
    <Flex
      pl={"90px"}
      pr={"64px"}
      pb={"50px"}
      className="sticky"
      sx={{
        gap: "64px",
        height: "100vh",
        fontFamily: "heading",
        top: 0,
        bottom: 0,
        transition: "background-color 500ms",
        backgroundColor: "white",
        width: "550px",
        justifyContent: "space-between",
        flexDirection: "column",
        ...sx,
      }}
      role="navigation"
    >
      <Box sx={{ width: ["80px", "95px"] }} pt={6}>
        <Link to={prefix}>
          <img src={Logo} />
        </Link>
      </Box>
      <Flex
        sx={{
          flexDirection: "column",
          flex: "1",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: ["none", "flex"] }}>
          <NavLink small to={prefix}>
            Home
          </NavLink>
          {isFeaturedPage && (
            <NavLink onClick={toggleGridView} small ml={"28px"}>
              {isGridView ? "List View" : "Grid View"}
            </NavLink>
          )}
        </Box>
        {isFeaturedPage && featuredCases ? (
          <FeaturedCases
            item={featuredCases}
            isFeatured={isFeaturedPage}
            location={location}
            prefix={prefix}
          />
        ) : (
          nav?.items.map((i) => {
            if (i.internal?.type === "ContentfulFeaturedCases") {
              return (
                <FeaturedCases
                  key={i.id}
                  item={i}
                  location={location}
                  prefix={prefix}
                  mb={1}
                />
              )
            }
            if (i.internal.type === "ContentfulNavigationItem") {
              return (
                <NavLink mb={1} key={i.id} to={`${prefix}${i.link.slug}/`}>
                  {i.title}
                </NavLink>
              )
            }

            if (i.internal.type === "ContentfulPage") {
              return (
                <NavLink mb={0} key={i.id} to={`${prefix}${i.slug}/`}>
                  {i.title}
                </NavLink>
              )
            }

            return null
          })
        )}
      </Flex>
      <DesktopNavigationBottom navigation={subNav} prefix={prefix} />
    </Flex>
  )
}

const DesktopNavigationBottom = ({ navigation, prefix }) => {
  const location = useLocation()
  const someItemIsActive = navigation.items.some((navItem) => {
    return location?.pathname?.includes(navItem.slug)
  })
  return (
    <Flex
      sx={{
        color: "darkest",
        gap: "24px",
        flexDirection: ["column"],
        textTransform: "uppercase",
        fontSize: "6px",
        fontWeight: 300,
        a: {
          textDecoration: "none",
        },
      }}
    >
      <Flex
        sx={{
          a: {
            color: someItemIsActive ? "#D8D8D8" : "darkest",
            ":hover": {
              color: "darkest",
            },
            "&.active": {
              color: "darkest",
            },
          },
          gap: 3,
          fontWeight: "400",
          fontSize: 1,
        }}
      >
        {navigation.items.map((navItem) => {
          return (
            <Link
              activeClassName="active"
              to={`${prefix}${navItem.slug}/`}
            >
              {navItem.title}
            </Link>
          )
        })}
      </Flex>
      <Flex sx={{ gap: [4] }}>
        <Box>
          <Box>Copyright © {new Date().getFullYear()}</Box>
          <Box>All Rights Reserved</Box>
        </Box>
        <Box>
          <Box>
            <a href="https://instagram.com/blonde___inc">Instagram</a>
            {" / "}
            <a href="https://vimeo.com/blondeinc">Vimeo</a>
            {" / "}
            <a href="https://linkedin.com/company/blondeinc">LinkedIn</a>
          </Box>
          <Box
            sx={{
              a: {
                color: "darkest"
              },
            }}
          >
            <Link activeClassName="active" to={`${prefix}privacy/`}>
              Privacy Policy
            </Link>
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}
