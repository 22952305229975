import React from "react"
import TextModule from "../text-module"
import Steps from "../text/steps"

const ContentModule = (props) => {
  const { module, padTop, padBottom, padX, prefix, pref, data, key } = props

  switch (module.__typename) {
    case "ContentfulTextModule":
      switch (module.showAsSteps) {
        case true:
          return (
            <>
              <Steps
                key={module.id}
                data={module}
                padTop={padTop}
                padBottom={padBottom}
                padX={padX}
              />
            </>
          )
        default:
          return (
            <>
              <TextModule
                key={module.id}
                data={module}
                padTop={padTop}
                padBottom={padBottom}
                padX={padX}
              />
            </>
          )
      }
  }
}

export default ContentModule
