import { useLocation } from "@reach/router"
import { Box, Flex, Grid } from "@theme-ui/components"
import React, { useMemo } from "react"
import PeopleModule from "./people-module"
import RichText from "./text/rich-text"

const TextModule = ({ data, padBottom, padTop, padX, sx, ...rest }) => {
  const { mobileGap = 4, desktopGap = 4 } = data
  const location = useLocation()

  // The isContactPage specific styling may be moved to a separate .css file if more specific styling is added
  const isContactPage =
    location.pathname.includes("contact") ||
    location.pathname.includes("kontakt")

  const hasPeopleColumn = (data.columns ?? []).some(
    (col) => col.__typename === "ContentfulPeopleModule"
  )
  const hasNestedTextModule = (data.columns ?? []).some(
    (col) => col.__typename === "ContentfulTextModule"
  )

  const columnsTotal = useMemo(() => {
    if (hasPeopleColumn || hasNestedTextModule) return 1
    if (data.columns) return data.columns.length
    return 0
  }, [hasPeopleColumn, data.columns, hasNestedTextModule])

  const pt = useMemo(() => {
    if (isContactPage) return 0
    return padTop ? [5, 6] : [2, 3]
  }, [padTop, isContactPage])

  const pb = useMemo(() => {
    if (isContactPage) return 0
    return padBottom ? [5, 6] : [2, 3]
  }, [padBottom, isContactPage])

  return (
    <Flex
      className="text-module"
      sx={{
        maxWidth: "1200px",
        justifyContent: "center",
        width: "100%",
        alignItems: hasPeopleColumn ? "flex-start" : "stretch",
        ...sx,
      }}
      mx={"auto"}
      px={padX != undefined ? padX : [3, "40px"]}
      pt={pt}
      pb={pb}
      {...rest}
    >
      <Grid
        columns={[1, 1, columnsTotal]}
        sx={{
          columnGap: [mobileGap, desktopGap],
          width: "100%",
          rowGap: [mobileGap, desktopGap],
          display: isContactPage && hasNestedTextModule ? "flex" : "grid",
          flexWrap: isContactPage && hasNestedTextModule ? "wrap" : "nowrap",

          /** Mid section */
          ...(isContactPage &&
            hasNestedTextModule && {
              "& > div > div": {
                rowGap: [mobileGap, "48px"],
              },
            }),

          /** Top section */
          ...(isContactPage &&
            hasPeopleColumn &&
            !hasNestedTextModule && {
              rowGap: [mobileGap, "35px"],
            }),
        }}
      >
        {(data.columns || []).map((col, idx) => {
          if (col.__typename === "ContentfulTextModule") {
            return <TextModule px={0} pt={0} pb={0} key={col.id} data={col} />
          }
          if (col.__typename === "ContentfulTextColumn") {
            const { text, enlarge, center } = col
            return (
              <Box
                className="text-column"
                key={idx}
                sx={{
                  width: "100%",
                }}
              >
                <RichText
                  html={text.childMarkdownRemark.html}
                  enlarge={enlarge}
                  center={center}
                />
              </Box>
            )
          }
          if (col.__typename === "ContentfulPeopleModule") {
            return (
              <PeopleModule
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  "& > div": {
                    minWidth: "133px",
                    maxWidth: "142px",
                  },
                }}
                px={0}
                key={col.id}
                data={col}
                {...(isContactPage && {
                  pt: 0,
                  pb: 0,
                })}
              />
            )
          }
          return null
        })}
      </Grid>
    </Flex>
  )
}

export default TextModule
