import { Box, Flex } from "@theme-ui/components"
import { Link } from "gatsby"
import React, { useContext, useEffect, useState } from "react"

import Logo from "../assets/logo.svg"
import LogoBlonde from "./svg/logo"

import { useResponsiveValue } from "@theme-ui/match-media"
import { InterfaceContext } from "../context/interface"
import FeaturedCases from "./featured-case-nav"
import NavLink from "./nav-link"

export default ({ nav, subNav, location,sx}) => {
  const { locale } = useContext(InterfaceContext)
  const [isExpanded, setExpanded] = useState(false)
  const isSmall = useResponsiveValue([true, false])

  const toggleExpand = () => {
    setExpanded((s) => !s)
  }

  useEffect(() => {
    if (isExpanded && isSmall) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isExpanded, isSmall])

  const [lang] = locale.split("-")
  let prefix = "/"
  if (lang !== "en") {
    prefix = `/${lang}/`
  }

  return (
    <Flex
      sx={{
        color: "darkest",
        fontFamily: "heading",
        top: 0,
        bottom: "unset",
        transition: "background-color 500ms",
        backgroundColor: "white",
        zIndex: [1001, "unset"],
        justifyContent: "space-between",
        flexDirection: "column",
        position: "fixed",
        left: 0,
        right: 0,
        "&+*": {
          paddingTop: ["50px", 0],
        },
        ...sx,
      }}
      role="navigation"
    >
      {/** TOGGLE */}
      <Box
        px={4}
        sx={{
          height: "50px",
          display: ["flex", "none"],
          alignItems: "center",
          justifyContent: isExpanded ? "flex-end" : "space-between",
        }}
      >
        <Box
          onClick={toggleExpand}
          variant="navLink"
          sx={{
            color: "darkest",
            fontSize: isExpanded ? 4 : 2,
            transform: isExpanded ? "translateX(14px)" : "none",
          }}
        >
          {isExpanded ? <>&times;</> : "Menu"}
        </Box>
      </Box>

      <Flex
        style={{
          height: "calc(100dvh - 50px)",
        }}
        sx={{
          justifyContent: "space-between",
          flexDirection: "column",
          display: isExpanded ? "flex !important" : "none !important",
        }}
      >
        {/** CONTENT */}
        <Flex
          pt={0}
          p={4}
          sx={{
            flexGrow: 1,
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              gap: 5,
            }}
          >
            <Box sx={{ width: ["80px", "95px"] }}>
              <Link to={prefix}>
                <img src={Logo} />
              </Link>
            </Box>
            <Flex
              sx={{
                flexDirection: "column",
                flex: "1",
                justifyContent: "center",
              }}
            >
              <NavLink
                sx={{
                  a: {
                    fontSize: "7px",
                  },
                }}
                small
                to={prefix}
              >
                Home
              </NavLink>

              {nav?.items.map((i) => {
                if (i.internal?.type === "ContentfulFeaturedCases") {
                  return (
                    <FeaturedCases
                      onClick={() => setExpanded(false)}
                      key={i.id}
                      item={i}
                      location={location}
                      prefix={prefix}
                      mb={1}
                      sx={{
                        a: {
                          fontSize: "19px",
                          fontWeight: "300",
                        },
                      }}
                    />
                  )
                }
                if (i.internal.type === "ContentfulNavigationItem") {
                  return (
                    <NavLink
                      onClick={() => setExpanded(false)}
                      sx={{
                        a: {
                          fontSize: "19px",
                          fontWeight: "300",
                        },
                      }}
                      mb={1}
                      key={i.id}
                      to={`${prefix}${i.link.slug}/`}
                    >
                      {i.title}
                    </NavLink>
                  )
                }

                return (
                  <NavLink
                    onClick={() => setExpanded(false)}
                    sx={{
                      a: {
                        fontSize: "19px",
                        fontWeight: "300",
                      },
                    }}
                    mb={1}
                    key={i.id}
                    to={`${prefix}${i.slug}/`}
                  >
                    {i.title}
                  </NavLink>
                )
              })}
            </Flex>
          </Flex>

          <Flex
            sx={{
              flexDirection: ["column"],
              fontWeight: "300",
              fontSize: "9px",
              textTransform: "uppercase",
              gap: 2,
              a: {
                textDecoration: "none",
              },
            }}
          >
            <a target="_blank" href="https://instagram.com/blonde___inc">
              Instagram
            </a>
            <a target="_blank" href="https://vimeo.com/blondeinc">
              Vimeo
            </a>
            <a target="_blank" href="https://linkedin.com/company/blondeinc">
              LinkedIn
            </a>
            {subNav.items
              .filter((item) => nav?.items.every((i) => i.id !== item.id))
              .map((i) => {
                return (
                  <Link
                    onClick={() => setExpanded(false)}
                    to={`${prefix}${i.slug}/`}
                  >
                    {i.title}
                  </Link>
                )
              })}
            <Link onClick={() => setExpanded(false)} to={`${prefix}privacy`}>
              Privacy Policy
            </Link>
          </Flex>
        </Flex>
        {/** FOOTER */}

        <Flex
          p={4}
          py={"44px"}
          sx={{
            backgroundColor: "background",
            textTransform: "uppercase",
            justifyContent: "space-between",
            alignItems: "flex-end",
            fontSize: "7px",
            color: "darkest",
            fontWeight: 300,
            lineHeight: 1.5,
          }}
        >
          <Box mb={"-4px"}>
            <Box>Copyright © {new Date().getFullYear()}</Box>
            <Box>All Rights Reserved</Box>
          </Box>
          <LogoBlonde width={110} />
        </Flex>
      </Flex>
    </Flex>
  )
}
